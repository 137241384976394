<app-layout>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
      <sidenav-menu (sidenavClose)="sidenav.close()"></sidenav-menu>
    </mat-sidenav>
    <mat-sidenav-content>
      <navbar-menu (sidenavToggle)="sidenav.toggle()"></navbar-menu>
      <main>
        <router-outlet></router-outlet>
      </main>
      <footer>
        <page-footer></page-footer>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</app-layout>