<div class="main-banner">
    <picture style>
        <source media="(min-width: 1024px) and (orientation: landscape)" srcset="assets/images/test_banner.png">
        <source media="(min-width: 1024px)" srcset="assets/images/test_banner.png">
        <img loading="lazy" src="assets/images/AdobeStock_408308630.jpeg" alt="Test" title="Test"
        style="width: 100%;"  rel="preload"/>
    </picture>
    <div class="main-banner-info">
        <h1>
            <p>En Dominio Ágil hacemos </p>
            <p><strong>Transformación Digital </strong></p>
            <p>para tu organización.</p>
        </h1>
        <!-- <button mat-button>Ver más</button> -->
        
    </div>
</div>
<div class="page-content">
    <div class="info-container info-container-2">
        <img src="assets/logos/logo_color-01.png" alt="" title="">

        <div>
            <h2>¿Qué es la Transformación Digital?</h2>
            <p>La <strong>transformación digital</strong> es la reinvención de una organización a través de la utilización de la tecnología <strong>digital</strong> para mejorar la forma en que la organización se desempeña y sirve a quienes la constituyen. <strong>Digital</strong> se refiere al uso de la tecnología que genera, almacena y procesa los datos.
            </p>
            <!-- <button mat-button>Ver más</button> -->
        </div>
    </div>
    <div class="info-container info-container-2 info-container-2a">
        

        <div>
            <h2>¿Cómo lo hacemos?</h2>
            <p>Tenemos un grupo colaborativo ágil para poder entregar resultados de los productos detectados en tiempos cortos, para que el cliente pueda experimentar y decidir si la solución es la que está buscando
            </p>
            <!-- <button mat-button>Ver más</button> -->
            
        </div>
        <img src="assets/images/AdobeStock_221022308.jpeg" alt="" title="">
    </div>
   <!--  <h2 class="page-title">¿Cómo lo hacemos?</h2>
    <p class="page-information">Tenemos un grupo colaborativo ágil para poder entregar resultados de los productos detectados en tiempos cortos, para que el cliente pueda experimentar y decidir si la solución es la que está buscando</p>
    <img class="page-logo" src="assets/logos/logo_color-01.png" alt="logo dominio ágil" title="logo dominio ágil"> -->
    
    <div class="info-container-1">
        <div>
            <h3>Nosotros te ayudamos para dar este paso con la ayuda de las tecnologías actuales:</h3>
            <ul>
                <li><fa-icon class="tech-icons" [icon]="faLayerGroup"></fa-icon>Desarrollo ágil</li>
                <li><fa-icon class="tech-icons" [icon]="faCloud"></fa-icon>Cloud</li>
                <li><fa-icon class="tech-icons" [icon]="faShieldAlt"></fa-icon>Seguridad de datos</li>
                <li><fa-icon class="tech-icons" [icon]="faDatabase"></fa-icon>Análisis y ciencia de datos</li>
                <li><fa-icon class="tech-icons" [icon]="faCode"></fa-icon>IoT</li>
                <li><fa-icon class="tech-icons" [icon]="faWindowRestore"></fa-icon>Migraciones</li>
                <li><fa-icon class="tech-icons" [icon]="faCogs"></fa-icon>Resolución de problemas complejos</li>
                <li><fa-icon class="tech-icons" [icon]="faGoogle"></fa-icon>Posicionamiento SEO</li>
                <li><fa-icon class="tech-icons" [icon]="faUsers"></fa-icon>Capacitaciones</li>
            </ul>
            <!-- <button mat-button routerLink="/services">Saber más</button> -->
        </div>
    </div>   

    <div class="clients-container">
        <h2 class="clients-title">Nuestros Clientes</h2> 
        <brands-carousel></brands-carousel>
    </div>
</div>
