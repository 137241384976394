import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from "@angular/flex-layout";
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MaterialModule } from './modules/material/material.module';
import { RoutingModule } from './modules/routing/routing.module';
import { AppComponent } from './app.component';
import { NavbarMenu } from './components/navbar-menu/navbar-menu.component';
import { HomePage } from './views/home-page/home-page.component';
import { ContactPage } from './views/contact-page/contact-page.component';
import { ServicePage } from './views/services-page/services-pages.component';
import { BrandsCarousel } from './components/brands-carousel/brands-carousel.component';
import { PageFooter } from './components/page-footer/page-footer.component';
import { LayoutComponent } from './components/layout/layout.component';
import { SidenavMenuComponent } from './components/sidenav-menu/sidenav-menu.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarMenu,
    HomePage,
    ServicePage,
    ContactPage,
    BrandsCarousel,
    PageFooter,
    LayoutComponent,
    SidenavMenuComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    IvyCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    RoutingModule,
    MaterialModule,
    HttpClientModule,
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
