<div class="footer-background">
    <div class="footer-content">
        <!-- <div class="footer-links">
            <div class="footer-columns">
                <ul>
                    <li class="footer-col-title">Enlaces</li>
                    <li><a href="#">Inicio</a></li>
                    <li><a href="#">Servicios</a></li>
                    <li><a href="#">Nosotros</a></li>
                    <li><a href="#">Contacto</a></li>
                </ul>
                <ul>
                    <li class="footer-col-title">Footer title</li>
                    <li><a href="#">Item</a></li>
                    <li><a href="#">Item</a></li>
                    <li><a href="#">Item</a></li>
                </ul>
            </div>
            <div class="social-networks">
                <h2>Síguenos</h2>
                <div class="networks-icons">
                    <img class="icon-twitter" src="assets/logos/twitter-brands.svg" alt="twitter">
                    <img class="icon-facebook" src="assets/logos/facebook-f-brands.svg" alt="facebook">
                    <img class="icon-instagram" src="assets/logos/instagram-brands.svg" alt="instagram">
                </div>
            </div>
        </div> -->
        <div id="contact-form" class="footer-form">
            <h2>Contáctanos</h2>
            <p>La transformación digital hace referencia a la forma en que algunas empresas describen sus esfuerzos para reunir las nuevas tecnologías, los procesos y la cultura con un objetivo en común. Este propósito puede ser mejorar la experiencia del cliente, generar innovaciones con mayor rapidez o simplemente contar con más recursos para sobrevivir mientras la revolución digital transforma al sector.</p>
            <span class="form-title">Formulario de contacto</span>
            <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                <div class="form-group">
                    <label for="firstname">Nombre</label>
                    <input
                    type="text"
                    class="form-control"
                    name="firstname"
                    placeholder="Escribe tu nombre aquí"
                    formControlName="firstname"
                    />
                </div>
                <div class="form-group">
                    <label for="lastname">Apellido</label>
                    <input
                    type="text"
                    class="form-control"
                    name="lastname"
                    placeholder="Escribe tu apellido aquí"
                    formControlName="lastname"
                    />
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input
                    type="email"
                    class="form-control"
                    name="email"
                    aria-describedby="emailHelp"
                    placeholder="Escribe tu email aquí"
                    formControlName="email"
                    />
                </div>
                <div class="form-group">
                    <label for="phone">Teléfono</label>
                    <input
                    type="text"
                    class="form-control"
                    name="phone"
                    placeholder="Escribe tu número aquí"
                    formControlName="phone"
                    />
                </div>
                <div class="form-group">
                    <label for="subject">Asunto</label>
                    <input
                    type="text"
                    class="form-control"
                    name="subject"
                    formControlName="subject"
                    />
                </div>
                <div class="form-group form-message">
                    <label for="message">Mensaje</label>
                    <textarea
                    class="form-control"
                    formControlName="message"
                    placeholder="Describe tu problema"
                    name="message"
                    >
                    </textarea>
                </div>
                <button mat-button
                    type="submit"
                    [disabled]="!FormData.valid"
                    >
                    Enviar
                </button>
            </form>
        </div>
    </div>
</div>
<mat-toolbar class="footer-bar">
    <img src="assets/logos/logo_icono_blanco.svg" alt="">
    <span>2021 © Dominio Ágil. Todos los derechos reservados.</span>
</mat-toolbar>