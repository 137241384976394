import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MailerService {
  private contactUrl = 'http://dominioagil.cl:8181/api/contact'

  constructor(private http: HttpClient) { }

  public sendForm(formData: FormGroup) {
    console.log(formData)
    return this.http.post<FormGroup>(this.contactUrl, formData);
  }
}