<div class="contact-page">
    <h2>Contáctanos</h2>
    <p>La transformación digital hace referencia a la forma en que algunas empresas describen sus esfuerzos para reunir las nuevas tecnologías, los procesos y la cultura con un objetivo en común. Este propósito puede ser mejorar la experiencia del cliente, generar innovaciones con mayor rapidez o simplemente contar con más recursos para sobrevivir mientras la revolución digital transforma al sector.</p>
    <span class="form-title">Formulario de contacto</span>
    <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
        <div class="form-group">
            <label for="firstname">Nombre</label>
            <input
            type="text"
            class="form-control"
            name="firstname"
            placeholder="Escribe tu nombre aquí"
            formControlName="firstname"
            />
        </div>
        <div class="form-group">
            <label for="lastname">Apellido</label>
            <input
            type="text"
            class="form-control"
            name="lastname"
            placeholder="Escribe tu apellido aquí"
            formControlName="lastname"
            />
        </div>
        <div class="form-group">
            <label for="email">Email</label>
            <input
            type="email"
            class="form-control"
            name="email"
            aria-describedby="emailHelp"
            placeholder="Escribe tu email aquí"
            formControlName="email"
            />
        </div>
        <div class="form-group">
            <label for="phone">Telefono</label>
            <input
            type="text"
            class="form-control"
            name="phone"
            placeholder="Escribe tu número aquí"
            formControlName="phone"
            />
        </div>
        <div class="form-group">
            <label for="subject">Asunto</label>
            <input
            type="text"
            class="form-control"
            name="subject"
            formControlName="subject"
            />
        </div>
        <div class="form-group form-message">
            <label for="message">Mensaje</label>
            <textarea
            class="form-control"
            formControlName="message"
            placeholder="Describe tu problema"
            name="message"
            >
            </textarea>
        </div>
        <button mat-button
            type="submit"
            [disabled]="!FormData.valid"
            >
            Enviar
        </button>
    </form>
</div>