import { Component } from '@angular/core';

@Component({
  selector: 'services-pages',
  templateUrl: './services-pages.component.html',
  styleUrls: ['./services-pages.component.css']
})

export class ServicePage {

}
