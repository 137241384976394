import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { HomePage } from 'src/app/views/home-page/home-page.component';
import { ContactPage } from 'src/app/views/contact-page/contact-page.component';
import { AboutUs } from 'src/app/views/about-us/about-us.component';
import { ServicePage } from 'src/app/views/services-page/services-pages.component';


const routes: Routes = [
  { path: '', component: HomePage },
  { path: 'contact', component: ContactPage },
  { path: 'about-us', component: AboutUs },
  { path: 'services', component: ServicePage },
  { path: '', redirectTo: '', pathMatch: 'full' }
 
];
 
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class RoutingModule { }
