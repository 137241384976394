import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import { MailerService } from '../../services/mailer.service';

@Component({
  selector: 'page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.css']
})

export class PageFooter implements OnInit {
  FormData: FormGroup;
  constructor(private builder: FormBuilder, private mailerService: MailerService) { }

  ngOnInit() {
    this.FormData = this.builder.group({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
      phone: new FormControl('', [Validators.required]),
      subject: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required])
    });
    
  }

  onSubmit(formData) {
    console.log(formData)
    this.mailerService.sendForm(formData).subscribe()
    this.FormData.reset();
  }
}
