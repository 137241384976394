<mat-toolbar class="nav-menu">
  <div fxHide.gt-xs>
      <!-- <button mat-icon-button (click)="onToggleSidenav()">
          <mat-icon class="menu-icon">menu</mat-icon>
      </button> -->
  </div>
  <a class="nav-title" routerLink="/">
    <span class="logo-container">
      <img class="home-logo-icon" src="assets/logos/logo_icono_blanco.svg" alt="Dominio Ágil" name="Dominio Ágil">
      <img class="home-logo-text" src="assets/logos/logo_texto_blanco.svg" alt="Dominio Ágil" name="Dominio Ágil">
    </span>
  </a>
  <span class="menu-spacer"></span>
  <div fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
    <ul fxLayout fxLayoutGap="15px" class="nav-items">
      <!-- <li><a mat-button routerLink="/" class="nav-item active" type="button">Inicio</a></li> -->
      <!-- <li><a mat-button href="/services" class="nav-item" type="button">Servicios</a></li>
      <li><a mat-button href="/about-us" class="nav-item" type="button">Nosotros</a></li> -->
      <!-- <li><a mat-button routerLink="/contact" class="nav-item" type="button">Contacto</a></li> -->
    </ul>
  </div>
</mat-toolbar>
