<carousel
    class="brands-container"
    [cellsToShow]="slidesNum"
    [arrows]="!hideArrows"
    >
    <div class="carousel-cell">
        <img class="brand-img" src="assets/logos/logo_al_carro.svg">
    </div>
    <div class="carousel-cell">
        <img class="brand-img" src="assets/logos/logo_sochedem.png">
    </div>
    <div class="carousel-cell">
        <img class="brand-img" src="assets/logos/logo_larre.svg">
    </div>
    <div class="carousel-cell">
        <img class="brand-img" src="assets/logos/logo_neuromaflob.png">
    </div>
</carousel>