import { Component } from '@angular/core';
import { faLayerGroup, faCogs, faCloud,
         faShieldAlt, faDatabase, faCode,
         faWindowRestore, faUsers } from '@fortawesome/free-solid-svg-icons';

import { faGoogle } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})

export class HomePage {
  faLayerGroup = faLayerGroup;
  faCogs = faCogs;
  faCloud = faCloud;
  faShieldAlt = faShieldAlt;
  faDatabase = faDatabase;
  faCode = faCode;
  faWindowRestore = faWindowRestore;
  faUsers = faUsers;
  faGoogle = faGoogle
}
