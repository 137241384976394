import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'brands-carousel',
  templateUrl: './brands-carousel.component.html',
  styleUrls: ['./brands-carousel.component.css']
})


export class BrandsCarousel {
  public innerWidth: any;
  public slidesNum: number;
  public hideArrows: boolean = false;
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 992) {
      this.slidesNum = 4; 
      this.hideArrows = true;
    }
    else if (this.innerWidth > 600) {
      this.slidesNum = 2;
    }
    else {
      this.slidesNum = 2;
    }
  } 
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth > 992) {
      this.slidesNum = 4;
      this.hideArrows = true;
    }
    else if (this.innerWidth > 600) {
      this.slidesNum = 2;
    }
    else {
      this.slidesNum = 2;
    }
  }

}
